<template>
  <transition
    enter-active-class="animate__animated animate__faster animate__fadeIn"
    leave-active-class="animate__animated animate__faster animate__fadeOut"
  >
    <div class="header" v-if="Navigation.visible">
      <nav :class="['header-nav']" :style="{ ...header_background_class }">
        <div class="header-wrapper">
          <div class="w-fit flex gap-2 justify-center">
            <a
              v-if="!user_is_logged"
              :href="url({ path: !user_is_logged ? '/' : '/journey' })"
              :title="gt('Home')"
            >
              <img
                :src="logo_icon"
                class="h-10 rounded-lg cursor-pointer"
                :alt="gt('memeonlyme')"
                :title="gt('memeonlyme')"
                :aria-label="gt('memeonlyme')"
              />
            </a>

            <template v-if="!user_is_logged">
              <ul class="left-menu">
                <li>
                  <a
                    :href="url({ path: '/routes' })"
                    :title="gt('Routes')"
                    :class="[
                      'nav-item',
                      is_active(['/routes', '/route']) ? 'active' : ''
                    ]"
                  >
                    <i class="fi fi-rr-playing-cards"></i>
                    {{ gt('Routes') }}
                  </a>
                </li>

                <li class="hidden md:flex">
                  <a
                    :href="url({ path: '/blog' })"
                    :title="gt('Posts')"
                    :class="['nav-item', is_active(['/blog']) ? 'active' : '']"
                  >
                    <i class="fi fi-rr-book-open-cover"></i>
                    {{ gt('Posts') }}
                  </a>
                </li>
              </ul>
            </template>
            <template v-if="user_is_logged">
              <ul class="left-menu">
                <li v-if="go_back">
                  <a :href="go_back" :class="['nav-item']">
                    <i class="fi fi-rr-angle-left"></i>
                    {{ gt('Go Back') }}
                  </a>
                </li>
                <li v-if="title">
                  <a :class="['nav-item', 'active']">
                    {{ title }}
                  </a>
                </li>
              </ul>
            </template>
          </div>

          <div class="w-fit flex">
            <template v-if="user_is_logged">
              <ul class="flex w-full gap-1 list-none items-center justify-end">
                <li>
                  <user_menu_component />
                </li>
              </ul>
            </template>

            <template v-if="!user_is_logged">
              <ul class="flex w-full gap-1 list-none items-center justify-end">
                <li>
                  <a
                    class="nav-item active"
                    :href="url({ path: '/login' })"
                    :title="gt('Sign In')"
                  >
                    <i class="fi fi-rr-user leading-[0] text-xl"></i>
                    {{ gt('Sign In') }}
                  </a>
                </li>
              </ul>
            </template>
          </div>
        </div>
      </nav>
    </div>
  </transition>

  <!-- Player -->
  <teleport to="body">
    <player_component />
  </teleport>

  <!--  Popups -->
  <teleport to="body">
    <transition
      enter-active-class="animate__animated animate__faster animate__slideInUp"
      leave-active-class="animate__animated animate__faster animate__fadeOut"
    >
      <popup_component v-if="popup.active" :notification="popup">
        <template v-if="popup?.title" #title>
          {{ popup.title }}
        </template>

        <template #message>
          <div v-html="popup.message"></div>
        </template>
      </popup_component>
    </transition>

    <!-- User Menu -->
    <app_profile_modal_component v-if="user_is_logged" />
  </teleport>
</template>

<script setup>
  // Components
  import {
    defineAsyncComponent,
    onMounted,
    onUnmounted,
    ref,
    computed
  } from 'vue'

  const app_profile_modal_component = defineAsyncComponent(
    () => import('@/components/template/app/modals/profile/index.vue')
  )

  const player_component = defineAsyncComponent(
    () => import('@/components/app/player/index.vue')
  )

  const popup_component = defineAsyncComponent(
    () => import('@/components/commons/popup.vue')
  )

  const user_menu_component = defineAsyncComponent(
    () => import('./components/header/user_menu.vue')
  )

  // Methods
  import Plan from '@/methods/for_plans'

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Stores
  import UserStore from '@/stores/user'
  import AppStore from '@/stores/app'
  import NavigationStore from '@/stores/top_navigation'

  // Images
  import logo_icon from '@/assets/logos/logo-icon.jpeg'

  // Lib
  import url from '@/helpers/url'
  import get_words from '@/helpers/get_words'
  import get_shortened_text from '@/helpers/get_shortened_text'
  import get_locale from '@/helpers/get_locale'

  // Variables
  const User = UserStore()
  const Navigation = NavigationStore()
  const App = AppStore()

  const is_scrolled = ref(false)
  const last_scroll_position = ref(0)
  const plan = ref({})
  const { is_logged: user_is_logged, user: user_logged } = User
  const { popup } = App

  // Computed
  const title = computed(() =>
    get_shortened_text(get_words(Navigation.title, 5), 20)
  )
  const go_back = computed(() => Navigation.go_back)
  const header_background_class = computed(() => {
    const { background, opacity } = Navigation
    let background_color = background === 'light' ? '255,255,255' : '0,0,0'
    let style = `rgba(${background_color},${opacity})`

    return {
      backgroundColor: style
    }
  })

  // Methods
  const get_plans = async function () {
    if (!user_is_logged) {
      return
    }

    const { success, plans } = await Plan.my_plans()

    if (success) {
      plan.value = get_plan(plans)
    }
  }

  const get_plan = function (plans) {
    let plan = plans.find((plan) => plan.starter === 1)

    if (plan?.progress === 100) {
      plan = plans[plans.length - 1]
    }

    return plan
  }

  function is_active(path = []) {
    return path.some((route) => {
      if (route === '/') {
        return window.location.pathname === '/'
      }

      return window.location.pathname.startsWith(route)
    })
  }

  function handle_scroll() {
    const current_scroll = window.scrollY
    const open = current_scroll <= last_scroll_position.value

    if (!Navigation.disabled) {
      Navigation.visible = open
    }

    is_scrolled.value = current_scroll > 0
    last_scroll_position.value = current_scroll
  }

  function handle_public_notifications() {
    window.Echo.channel('public.notifications').listen(
      '.notification',
      (data) => {
        const { locale, level, title, description, content } = data

        if (locale !== get_locale()) {
          return
        }

        App.notification({
          title,
          message: description,
          level,
          close_after: 100000
        })
      }
    )
  }

  function handle_private_notifications() {
    if (!user_is_logged) {
      return
    }

    window.Echo.channel(`App.Models.User.${user_logged.id}`).listen(
      '.notification',
      (data) => {
        const { locale, level, title, description, content } = data

        App.notification({
          title,
          message: description,
          level
        })
      }
    )
  }

  // Lifecycle
  onMounted(() => {
    get_plans()
    handle_public_notifications()
    handle_private_notifications()
    window.addEventListener('scroll', handle_scroll)
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', handle_scroll)
  })
</script>
