<template>
  <div class="container !max-w-screen-sm">
    <form class="w-full flex flex-col gap-2" @submit.prevent="submit()">
      <input_component
        :settings="form.inputs.usage_frequency"
        @update:value="form.inputs.usage_frequency.value = $event"
      />

      <input_component
        :settings="form.inputs.problem_to_solve"
        @update:value="form.inputs.problem_to_solve.value = $event"
      />

      <input_component
        :settings="form.inputs.problem_solved"
        @update:value="form.inputs.problem_solved.value = $event"
      />

      <input_component
        :settings="form.inputs.best_part"
        @update:value="form.inputs.best_part.value = $event"
      />

      <input_component
        :settings="form.inputs.frustrations"
        @update:value="form.inputs.frustrations.value = $event"
      />

      <input_component
        :settings="form.inputs.improvements"
        @update:value="form.inputs.improvements.value = $event"
      />

      <input_component
        :settings="form.inputs.recommendation_score"
        @update:value="form.inputs.recommendation_score.value = $event"
      />

      <input_component
        :settings="form.inputs.recommendation_reason"
        @update:value="form.inputs.recommendation_reason.value = $event"
      />

      <input_component
        :settings="form.inputs.ideas_or_suggestions"
        @update:value="form.inputs.ideas_or_suggestions.value = $event"
      />

      <input_component
        :settings="form.inputs.price_feedback"
        @update:value="form.inputs.price_feedback.value = $event"
      />

      <button_component
        :classes="['btn', 'btn-dark', 'w-fit']"
        :loading="form.is_loading"
        :submittable="true"
      >
        <i class="fi fi-rr-sign-in-alt"></i>
        {{ gt('Send your feedback') }}
      </button_component>
    </form>
  </div>
</template>

<script setup>
  // Components
  import { defineAsyncComponent } from 'vue'

  const button_component = defineAsyncComponent(
    () => import('@/components/commons/button.vue')
  )

  const input_component = defineAsyncComponent(
    () => import('@/components/commons/input.vue')
  )

  // Helpers
  import url from '@/helpers/url'
  import get_form_state from '@/helpers/get_form_state'

  // Translations needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Variables
  const form = get_form_state('feedback', [
    {
      label: gt('Usage frequency'),
      description: gt('How often do you use products like this?'),
      description_on_top: true,
      name: 'usage_frequency',
      type: 'select',
      options: [
        { id: 1, value: gt('Daily') },
        { id: 2, value: gt('Weekly') },
        { id: 3, value: gt('Monthly') },
        { id: 4, value: gt('First time') }
      ],
      default: 1,
      value: true
    },
    {
      label: gt('Problem'),
      description: gt('What issue were you trying to solve?'),
      description_on_top: true,
      name: 'problem_to_solve',
      type: 'textarea'
    },
    {
      label: gt('Solved problem'),
      description: gt('Did the product solve your issue? If yes, how?'),
      description_on_top: true,
      name: 'problem_solved',
      type: 'textarea'
    },
    {
      label: gt('Best part'),
      description: gt('What part of the product was most useful?'),
      description_on_top: true,
      name: 'best_part',
      type: 'textarea'
    },
    {
      label: gt('Frustrations'),
      description: gt('What did you find frustrating or unintuitive?'),
      description_on_top: true,
      name: 'frustrations',
      type: 'textarea'
    },
    {
      label: gt('Improvements'),
      description: gt('What would you change to make it better?'),
      description_on_top: true,
      name: 'improvements',
      type: 'textarea'
    },
    {
      label: gt('Recommendation'),
      description: gt(
        'On a scale of 1 to 10, how likely are you to recommend this product?'
      ),
      description_on_top: true,
      name: 'recommendation_score',
      type: 'select',
      options: Array.from({ length: 10 }, (_, i) => ({
        id: i + 1,
        value: `${i + 1}`
      }))
    },
    {
      label: gt('Reason for score'),
      description: gt('Why did you give this score?'),
      description_on_top: true,
      name: 'recommendation_reason',
      type: 'textarea'
    },
    {
      label: gt('Suggestions'),
      description: gt('What would you add or change in the product?'),
      description_on_top: true,
      name: 'ideas_or_suggestions',
      type: 'textarea'
    },
    {
      label: gt('Price feedback'),
      description: gt('Do you think the product is worth its price? Why?'),
      description_on_top: true,
      name: 'price_feedback',
      type: 'textarea'
    }
  ])

  // Methods
  const submit = async () => {}
</script>
