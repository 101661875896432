// Libs
import { defineStore } from 'pinia'
import { ref } from 'vue'

export default defineStore('top_navigation', () => {
  let visible = ref(true)
  let disabled = ref(false)
  let title = ref(null)
  let go_back = ref(null)
  let background = ref('light')
  let opacity = ref(0.9)

  return {
    visible,
    disabled,
    title,
    background,
    opacity,
    go_back
  }
})
