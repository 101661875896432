// Wrappers
import StoreWrapper from './helpers/wrappers/store'
import GetWrapper from './helpers/wrappers/get'
import DeleteWrapper from './helpers/wrappers/delete'

// Services
import RouteService from '@/services/route'

export default {
  async get(args = {}) {
    const { success, routes, pagination } = await GetWrapper(
      new RouteService().get,
      args
    )

    return {
      success,
      records: routes || [],
      pagination
    }
  },

  async delete({ id }) {
    const { success, message } = await DeleteWrapper(
      new RouteService().delete,
      { id, append: { force: true } }
    )

    return {
      success,
      message
    }
  },

  async store({ $form, append = {} }) {
    return await StoreWrapper(new RouteService().store, {
      $form,
      append
    })
  },

  async get_stats(args = {}) {
    const { success, route, liked_by_me } = await GetWrapper(
      new RouteService().get_stats,
      args
    )

    return {
      success,
      route,
      liked_by_me
    }
  },

  async assign_symptom({ route_id, symptom_id }) {
    return await StoreWrapper(new RouteService().assign_symptom, {
      append: {
        route_id,
        symptom_id
      }
    })
  },

  async unassign_symptom({ route_id, symptom_id }) {
    return await StoreWrapper(new RouteService().unassign_symptom, {
      append: {
        route_id,
        symptom_id
      }
    })
  },

  async apply_primary_symptom({ route_id, symptom_id }) {
    return await StoreWrapper(new RouteService().apply_primary_symptom, {
      append: {
        route_id,
        symptom_id
      }
    })
  },

  async get_properties(args = {}) {
    const { success, properties, pagination } = await GetWrapper(
      new RouteService().get_properties,
      args
    )

    return {
      success,
      records: properties || [],
      pagination
    }
  },

  async get_categories(args = {}) {
    args.per_page = 100

    const { success, categories, pagination } = await GetWrapper(
      new RouteService().categories,
      args
    )

    return {
      success,
      records: categories || [],
      pagination
    }
  },

  async update_like({ route_id }) {
    return await StoreWrapper(new RouteService().update_like, {
      append: {
        route_id
      }
    })
  },

  async categories(args = {}) {
    args.per_page = 100

    const { success, categories, pagination } = await GetWrapper(
      new RouteService().categories,
      args
    )

    return {
      success,
      records: categories || [],
      pagination
    }
  },

  async delete_category({ id }) {
    const { success, message } = await DeleteWrapper(
      new RouteService().delete_category,
      { id, append: { force: true } }
    )

    return {
      success,
      message
    }
  },

  async update_category({ $form, append = {} }) {
    return await StoreWrapper(new RouteService().update_category, {
      $form,
      append
    })
  }
}
