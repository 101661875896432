<template>
  <div class="container !max-w-[480px]">
    <transition>
      <div class="flex flex-col gap-4 w-full">
        <div class="flex flex-col gap-2 mt-10">
          <title_component :size="'large'">
            <template #title>
              {{ gt('Hey') }}
              <br />
              {{ user_name }} 👋
            </template>
            <template #subtitle>
              {{ gt('Continue with your journey.') }}
            </template>
          </title_component>
        </div>

        <plan_component></plan_component>
      </div>
    </transition>
  </div>
</template>

<script setup>
  // Components
  import { defineAsyncComponent } from 'vue'

  const title_component = defineAsyncComponent(
    () => import('@/components/commons/title.vue')
  )

  const plan_component = defineAsyncComponent(
    () => import('./components/plan.vue')
  )

  // Helpers
  import url from '@/helpers/url'

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Libs
  import { onBeforeMount, computed } from 'vue'

  // Helpers
  import get_words from '@/helpers/get_words'

  // Stores
  import UserStore from '@/stores/user'
  import TopNavigationStore from '@/stores/top_navigation'
  import BottomNavigationStore from '@/stores/bottom_navigation'

  // Variables
  const User = UserStore()
  const BottomNavigation = BottomNavigationStore()
  const TopNavigation = TopNavigationStore()

  const { user, is_logged } = User

  // Computed variables
  const user_name = computed(() => is_logged && get_words(user.name, 1, ''))

  // Methods

  // Lifecycle
  onBeforeMount(() => {
    TopNavigation.title = gt('My Journey')
    BottomNavigation.visible = true
    BottomNavigation.active_menu = 'journey'

    if (!is_logged) {
      window.location.href = url({
        path: '/login',
        params: { redirect_to: window.location.pathname }
      })
    }
  })
</script>
