import { reactive } from 'vue'

const default_input = {
  id: null,
  type: 'text',
  value: '',
  errors: [],
  options: [],
  placeholder: '',
  label: '',
  required: false,
  disabled: false,
  name: '',
  description: null,
  description_on_top: false
}

const initialize_inputs = (inputs) => {
  return inputs.reduce((acc, input) => {
    const temp_id = Math.random().toString(36).substring(7)

    if (!input.name) {
      console.error('Input name is required')
      return acc
    }

    if (!input.type) {
      console.error('Input type is required')
      return acc
    }

    acc[input.name] = {
      ...default_input,
      ...input,
      id: input.id || temp_id,
      placeholder: input.placeholder || input.label || ''
    }

    return acc
  }, {})
}

export default (form_id, inputs = []) => {
  return reactive({
    id: form_id,
    is_loading: false,
    error: null,
    inputs: initialize_inputs(inputs)
  })
}
