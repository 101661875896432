// Libs
import { defineStore } from 'pinia'
import { ref } from 'vue'

export default defineStore('bottom_navigation', () => {
  let visible = ref(true)
  let disabled = ref(false)
  let active_menu = ref(null)

  return {
    visible,
    disabled,
    active_menu
  }
})
